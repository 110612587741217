// @ts-nocheck
import { httpRequest, httpRequestUpyun } from '@/utils/request'
import * as types from './mutation-types'
import { upyunUrl, platform } from '@/api/config'
export default {
  // --------------------------AI面试-----------------------------
  // 生成小程序二维码 查看报告
  getShareQrcodeMa({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/common/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'POST',
          url: `${prefixUrl}/qrcode/get_share_qrcode`,
          data
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 获取机器-渠道校验参数
  getLoginChannelVerify({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/common/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'POST',
          url: `${prefixUrl}/qrcode/login_channel_verify`,
          data
        })
        .then(res => {
          resolve(res)
        })
    })
  },
  // 获取民族
  getNationLists({ dispatch, commit, state }) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/common/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'GET',
        url: `${prefixUrl}/support/nation_list`
      }).then(res => resolve(res.data))
    })
  },
  // 获取面试题目
  getInterviewRecordQuestion({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/train/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `${prefixUrl}/training/get_training?channel=${data.channel}`
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 获取又拍云签名
  getUpYunSign({ dispatch, commit, state }) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/common/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `${prefixUrl}/support/get_upyun_sign`
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 上传视频到又拍云
  videoUpyun({ dispatch, commit, state }, data) {
    return new Promise(resolve => {
      httpRequestUpyun
        .request({
          method: 'POST',
          url: `/interview-tzkj`,
          data
        })
        .then(res => {
          resolve(res)
        })
    })
  },
  // 上传简历到又拍云
  resumeFileUpyun({ dispatch, commit, state }, data) {
    return new Promise(resolve => {
      httpRequestUpyun
        .request({
          method: 'POST',
          url: `/interview-tzkj`,
          data
        })
        .then(res => {
          const title = sessionStorage.getItem('resume_title')
          const obj = {
            resume_file_size: res.file_size,
            url: upyunUrl + res.url,
            resume_name: title
          }
          sessionStorage.setItem('resumeFile', JSON.stringify(obj))
          resolve(res)
        })
    })
  },
  // 上传简历刀又拍云 配合简历导出pdf使用
  resumeFileUpyunPdf({ dispatch, commit, state }, data) {
    return new Promise(resolve => {
      httpRequestUpyun
        .request({
          method: 'POST',
          url: `/interview-tzkj`,
          data
        })
        .then(res => {
          resolve(res)
        })
    })
  },
  //上传简历-获取页面转成图片的img 方便小程序调用到又拍云
  resumeFileUpyunImg({ dispatch, commit, state }, data) {
    return new Promise(resolve => {
      httpRequestUpyun
        .request({
          method: 'POST',
          url: `/interview-tzkj`,
          data
        })
        .then(res => {
          const obj = {
            resume_file_size: res.file_size,
            url: upyunUrl + res.url
          }
          resolve(obj)
        })
    })
  },
  // 上传答题
  submitAnswer({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/train/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'POST',
          url: `${prefixUrl}/training/save_answer`,
          data
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 获取面试结果
  getInterviewResult({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/train/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `${prefixUrl}/training/get_answer?channel=${data.channel}`
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 获取登录二维码
  getLoginQrcode({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/common/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `${prefixUrl}/qrcode/get_login_qrcode&channel=${data.channel}`
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 获取二维码状态信息
  getLoginScanInfo({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/common/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `${prefixUrl}/qrcode/login_scan_info?scene=${data.scene}&channel=${data.channel}`
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 获取面试岗位分类
  getInterviewTrainingCategory({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/train/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `${prefixUrl}/interview_training/category?from_view=${data.from_view}&channel=${data.channel}`
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 获取面试岗位列表
  getListByCate({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/train/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `${prefixUrl}/interview_training/list_by_cate?category_id=${data.category_id}&page=${data.page}&page_size=${data.page_size}&channel=${data.channel}`
        })
        .then(res => {
          res.data.data.forEach(item => {
            item['popUpsState'] = false
          })
          resolve(res.data.data)
        })
    })
  },
  // 获取面试岗位详情
  getInterviewTrainingSpecial({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/train/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `${prefixUrl}/interview_training/special?id=${data.id}&channel=${data.channel}`
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 获取面试有招题目
  getInterviewTrainingDetail({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/train/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `${prefixUrl}/interview_training/${data.id}?type=${data.type}&yz_activity_id=${data.yz_activity_id}&channel=${data.channel}`
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 上传答题到面试有招
  onSubmitInterviewTrainingSaveAnswer({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/train/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'POST',
          url: `${prefixUrl}/interview_training/save_answer`,
          data
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 获取面试报告列表
  getReportList({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/train/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `${prefixUrl}/training/get_answer_list?channel=${data.channel}&page_size=${data.page_size}&page=${data.page}`
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 清空报告列表
  emptyReportList({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/train/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'POST',
          url: `${prefixUrl}/training/school_delete?channel=${data.channel}`
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 我的训练详情
  getInterviewTrainDetail({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/train/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'POST',
        url: `${prefixUrl}/interview_training/answer_detail`,
        data
      }).then(res => {
        resolve(res.data)
      })
    })
  },
  // ------------------------ PC简历诊断 --------------------------
  // 简历诊断岗位列表
  getResumeDiagnosisPositionList({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `${prefixUrl}/resume_diagnosis/position?search=${data.search}&page=1`
        })
        .then(res => {
          resolve(res.data.data)
        })
    })
  },
  // 简历诊断已有多少人参与分析
  getResumeDiagnosisParticipantList({ dispatch, commit, state }) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `${prefixUrl}/resume_diagnosis/user_list`
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 开始诊断简历
  resumeDiagnosisSave({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'POST',
          url: `${prefixUrl}/resume_diagnosis/save`,
          data
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 获取简历诊断提交记录
  getResumeDiagnosisRecord({ dispatch, commit, state }) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `${prefixUrl}/resume_diagnosis/get_record_new`
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 获取用户信息
  getUserInfo({ dispatch, commit, state }) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/common/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'GET',
        url: `${prefixUrl}/user/user_info`
      }).then(res => {
        commit(types.SET_USERINFO, res)
        resolve(res)
      })
    })
  },
  // 获取简历诊断报告内容
  getResumeDiagnosisReport({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'GET',
        url: `${prefixUrl}/resume_diagnosis/get_report_new?id=${data}`
      }).then(res => {
        resolve(res.data)
      })
    })
  },
  // 简历自动生成的配置
  getResumeGenerateConfig({ dispatch, commit, state }) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'GET',
        url: `${prefixUrl}/resume_generate/config`
      }).then(res => {
        resolve(res.data)
      })
    })
  },
  // 简历自动生成的内容
  getResumeGenerateContent({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'GET',
        url: `${prefixUrl}/resume_generate/get_resume?id=${data.id || 0}`
      }).then(res => {
        let obj = res.data
        if (obj && obj.id) {
          // 把工作经历的描述已空格为准 切割成数组
          if (obj.work_experience.length) {
            obj.work_experience.forEach(item => {
              item.work_experienceList = item.description.split('\n')
            })
          }
          if (obj.intention.length) {
            obj.intention.forEach(item => {
              const startIndex = item.city.indexOf('省') + 1
              const endIndex = item.city.indexOf('市') + 1
              item.cantonal = item.city.substring(startIndex, endIndex)
            })
          }
          commit(types.SET_CURRENT_RESUME_EDIT_ID, obj.id)
          commit(types.SET_CURRENT_RESUME_INFO, obj)
        }
        resolve(obj)
      })
    })
  },
  //上传简历解析接口
  getResumeAnalysisContent({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'
    return new Promise(resolve => {
      httpRequest({
        method: 'POST',
        url: `${prefixUrl}/resume_generate/analysis`,
        data
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(error => {
          console.log(error)
        })
    })
  },
    //上传简历发送至用户邮箱
    getResumeAnalysisEmail({ dispatch, commit, state }, data) {
      let prefixUrl = ''
      if (platform === 'digitalEmployment') prefixUrl = '/resume/web'
      return new Promise(resolve => {
        httpRequest({
          method: 'POST',
          url: `${prefixUrl}/resume_generate/send_email`,
          data
        })
          .then(res => {
            resolve(res)
          })
          .catch(error => {
            console.log(error)
          })
      })
    },
  // 获取三方省市区数据Lists
  getThreeAgreementAreaList({ dispatch, commit, state }) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/common/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'GET',
        url: `${prefixUrl}/support/get_area`
      }).then(res => {
        resolve(res.data)
      })
    })
  },
  // 保存简历内容
  resumeGenerateSave({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'POST',
        url: `${prefixUrl}/resume_generate/save`,
        data
      }).then(res => {
        commit(types.SET_CURRENT_RESUME_EDIT_ID, res.data.id)
        resolve(res)
      })
    })
  },
  //首页轮播图数据
  getSlideshowList({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'get',
        url: `${prefixUrl}/resume_generate/config`
      }).then(res => {
        resolve(res.data)
      })
    })
  },
  //复制简历接口
  getResumeCopy({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'POST',
        url: `${prefixUrl}/resume_generate/copy`,
        data
      }).then(res => {
        resolve(res.data)
      })
    })
  },
  //获取word模板简历列表区域
  getResumeLabelList({ dispatch, commit, state }) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'GET',
        url: `${prefixUrl}/resume_template_category`
      }).then(res => {
        resolve(res.data)
      })
    })
  },
  //获取用户的简历列表接口
  getMeRedactList({ dispatch, commit, state }) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'GET',
        url: `${prefixUrl}/resume_generate/get_resume_list`
      }).then(res => {
        resolve(res.data)
      })
    })
  },
  //获取在线简历列表接口
  getOnlineResumeList({ dispatch, commit, state }) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'GET',
        url: `${prefixUrl}/resume_template_category/list`
      }).then(res => {
        resolve(res.data)
      })
    })
  },
  //获取简历诊断历史报告列表
  getDiagnosisList({ dispatch, commit, state },data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'GET',
        url: `${prefixUrl}/resume_diagnosis/get_list`,
        params: data
      }).then(res => {
        resolve(res.data)
      })
    })
  },
  //删除简历记录接口
  rmoverDeleteResume({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'POST',
        url: `${prefixUrl}/resume_generate/del`,
        data
      }).then(res => {
        //删除本地的id
        commit(types.SET_CURRENT_RESUME_EDIT_ID, 0)
        resolve(res)
      })
    })
  },
  //获取扫码小程序的上传简历的状态
  getResumeUploading({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'GET',
        url: `${prefixUrl}/resume_generate/get_upload_file?scene=${data.scene}`
      }).then(res => {
        resolve(res)
      })
    })
  },
  //删除扫码小程序的上传简历的接口
  rmoverResumeUploading({ dispatch, commit, state }, data) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'POST',
        url: `${prefixUrl}/resume_generate/del_upload_file`,
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  // 创建小程序二维码  扫码上传简历
  getShareQrcode({ dispatch, commit, state }) {
    let prefixUrl = ''
    if (platform === 'digitalEmployment') prefixUrl = '/resume/web'

    return new Promise(resolve => {
      httpRequest({
        method: 'GET',
        url: `${prefixUrl}/resume_generate/get_upload_qrcode`
      }).then(res => {
        resolve(res)
      })
    })
  },
  // ------------------------ PC就业前景分析 --------------------------
  // 就业前景分析已有多少人参与分析
  getEmploymentAnalysisParticipantList({ dispatch, commit, state }, data) {
    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `/employment/user_list`
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 就业前景分析-工作经历-学历
  getEmploymentConfig({ dispatch, commit, state }, data) {
    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `/employment/config`
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 就业前景分析学校列表
  getEmploymentSchoolList({ dispatch, commit, state }, data) {
    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `/school?search=${data.search}&page=1&page_size=20`
        })
        .then(res => {
          resolve(res.data.data)
        })
    })
  },
  // 就业前景分析专业列表
  getEmploymentMajorList({ dispatch, commit, state }, data) {
    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `/employment/major?search=${data.search}&page=1&page_size=20`
        })
        .then(res => {
          resolve(res.data.data)
        })
    })
  },
  // 就业前景分析选择就业方向列表
  getEmploymentMajorIndustryList({ dispatch, commit, state }, data) {
    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `/employment/major/industry?major_id=${data.major_id}&major_name=${data.major_name}`
        })
        .then(res => {
          res.data.forEach(item => {
            item['mark'] = false
          })
          resolve(res.data)
        })
    })
  },
  // 就业前景分析省市区列表
  getSupportAreaList({ dispatch, commit, state }, data) {
    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'GET',
          url: `/support/area_list`
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 保存专业分析提交内容
  employmentSave({ dispatch, commit, state }, data) {
    return new Promise(resolve => {
      httpRequest
        .request({
          method: 'POST',
          url: `/employment/save`,
          data
        })
        .then(res => {
          resolve(res.data)
        })
    })
  },
  // 获取专业就业报告内容
  employmentReport({ dispatch, commit, state }, data) {
    return new Promise(resolve => {
      httpRequest({
        method: 'POST',
        url: `/employment/get_report_new`,
        data
      }).then(res => {
        resolve(res.data)
      })
    })
  }
}
